export const shopRoutes = [
    {
        path: '/store',
        load: () => import(/* webpackChunkName: 'restaurant' */ './shop/home')
    },
    {
        path: '/store/signup',
        load: () => import(/* webpackChunkName: 'restaurant-signup' */ './shop/Signup')
    },
    {
        path: '/store/dashboard',
        load: () => import(/* webpackChunkName: 'restaurant' */ './shop/Dashboard')
    },
    {
        path: '/store/login',
        load: () => import(/* webpackChunkName: 'login' */ './shop/login')
    },
    {
        path: '/store/edit-profile',
        load: () => import(/* webpackChunkName: 'login' */ './shop/EditProfile')
    },
    {
        path: '/store/document',
        load: () => import(/* webpackChunkName: 'login' */ './shop/Document')
    },
    {
        path: '/store/payment-delivery',
        load: () => import(/* webpackChunkName: 'login' */ './shop/PaymentDelivery')
    },
    {
        path: '/store/password',
        load: () => import(/* webpackChunkName: 'restaurant-password' */ './shop/changePassword')
    },
    {
        path: '/store/payout',
        load: () => import(/* webpackChunkName: 'restaurant-password' */ './shop/Payout/PayoutList')
    },
    {
        path: '/store/addPayout',
        load: () => import(/* webpackChunkName: 'restaurant-password' */ './shop/Payout/AddPayout')
    },
    {
        path: '/store/payout/failure',
        load: () => import(/* webpackChunkName: 'restaurant-password' */ './shop/Payout/FailedPayout')
    },
    {
        path: '/store/operating-hours',
        load: () => import(/* webpackChunkName: 'restaurant-operation-hours' */ './shop/operationHours')
    },
    {
        path: '/store/drivers',
        load: () => import(/* webpackChunkName: 'restaurant-drivers' */ './shop/drivers')
    },
    {
        path: '/store/driver/add',
        load: () => import(/* webpackChunkName: 'restaurant-menu-add' */ './shop/addDriver')
    },
    {
        path: '/store/driver/edit/:id',
        load: () => import(/* webpackChunkName: 'editRestaurant' */ './shop/editDriver')
    },
    {
        path: '/store/menu',
        load: () => import(/* webpackChunkName: 'restaurant-menu' */ './shop/category')
    },
    {
        path: '/store/menu/add',
        load: () => import(/* webpackChunkName: 'restaurant-menu-add' */ './shop/addMenu')
    },
    {
        path: '/store/menu/edit/:id',
        load: () => import(/* webpackChunkName: 'restaurant-menu-add' */ './shop/editMenu')
    },
    {
        path: '/store/menu/:id/item',
        load: () => import(/* webpackChunkName: 'restaurant-menu-add' */ './shop/item')
    },
    {
        path: '/store/preparation-time',
        load: () => import(/* webpackChunkName: 'restaurant-preparation-time' */ './shop/preparationTime')
    },
    {
        path: '/store/order-management/:type',
        load: () => import(/* webpackChunkName: 'restaurant-preparation-time' */ './shop/orderManageContainer')
    },
    {
        path: '/store/order-details/:type/:id',
        load: () => import(/* webpackChunkName: 'restaurant-preparation-time' */ './shop/orderDetails')
    },
    {
        path: '/store/transaction/:type',
        load: () => import(/* webpackChunkName: 'restaurant-preparation-time' */ './shop/transaction')
    },
    {
        path: '/password/verification',
        load: () => import('./shop/changePasswordUsingToken/')
    },
    {
        path: '/store/menu/modifier-groups',
        load: () => import(/* webpackChunkName: 'restaurant-modifiergroups' */ './shop/modifierGroups')
    },
    {
        path: '/store/menu/modifier-groups/add',
        load: () => import(/* webpackChunkName: 'restaurant-modifiergroups' */ './shop/addModifierGroups')
    },
    {
        path: '/store/menu/modifier-groups/edit/:id',
        load: () => import(/* webpackChunkName: 'restaurant-modifiergroups' */ './shop/editModifierGroups')
    },
    {
        path: '/store/menu/:menuId/item/:subMenuId/add',
        load: () => import(/* webpackChunkName: 'restaurant-addfooditem' */ './shop/addFoodItem')
    },
    {
        path: '/store/menu/:menuId/item/:subMenuId/edit/:id',
        load: () => import(/* webpackChunkName: 'restaurant-addfooditem' */ './shop/editFoodItem')
    },
    {
        path: '/store/manage-service-fee',
        load: () => import(/* webpackChunkName: 'manageServiceFee' */ './shop/manageServiceFee')
    },
];