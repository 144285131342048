require('dotenv').config();
/* eslint-disable max-len */

module.exports = {
  // default locale is the first one
  locales: [
    /* @intl-code-template '${lang}-${COUNTRY}', */
    'en-US',
    'es',
    'pt'	
    //'fr-FR',
    //'id-ID',
    //'ja-JA',
    //'ru-RU'
    /* @intl-code-template-end */
  ],

  // Node.js app
  port: process.env.PORT || 3000,

  // https://expressjs.com/en/guide/behind-proxies.html
  trustProxy: process.env.TRUST_PROXY || 'loopback',

  // SITE URL
  siteUrl: process.env.SITE_URL || 'https://www.shawseats.com',

  // API Gateway
  api: {
    // API URL to be used in the client-side code
    clientUrl: process.env.API_CLIENT_URL || '',
    // API URL to be used in the server-side code
    serverUrl:
      process.env.API_SERVER_URL ||
      `http://localhost:${process.env.PORT || 3000}`,

    apiEndpoint: process.env.API_ENDPOINT_URL || 'https://www.shawseats.com/api'
  },

  //Socket URL
  socketUrl: process.env.SOCKET_URL || 'https://www.shawseats.com',

  distance: process.env.DISTANCE,

  // Database
  databaseUrl: process.env.DATABASE_URL,

  // Maximum upload size
  maxUploadSize: process.env.MAX_UPLOAD_SIZE || 10,

  // CRONs Timezone
  cronTimezone: process.env.CRON_TIMEZONE,
  // Refer this link for valid timezone https://raw.githubusercontent.com/node-cron/tz-offset/master/generated/offsets.json

  // Licence Upload
  licenseuploadDir: process.env.LICENSE_UPLOAD_DIR || '/images/license/',

  // Vehicle Upload
  vehicleUploadDir: process.env.VEHICLE_UPLOAD_DIR || '/images/vehicle/',

  // Profile photo upload
  profilePhotouploadDir: process.env.PROFILE_PHOTO_UPLOAD_DIR || '/images/avatar/',

  // category photo upload
  categoryUploadDir: process.env.CATEGORY_PHOTO_UPLOAD_DIR || '/images/category/',

  // Logo photo upload
  logoUploadDir: process.env.LOGO_PHOTO_UPLOAD_DIR || '/images/logo/',

  // homepage photo upload
  homepageUploadDir: process.env.HOMEPAGE_UPLOAD_DIR || '/images/homepage/',

  // staticpage photo upload
  staticpageUploadDir: process.env.STATICPAGE_UPLOAD_DIR || '/images/staticpage/',

  // Delivery Vehicle Upload
  deliveryVehicleUploadDir: process.env.DELIVERY_VEHICLE_UPLOAD_DIR || '/images/delivery-vehicle/',

  //contentpage photo upload 
  contentPageUploadDir: process.env.CONTENTPAGE_UPLOAD_DIR || '/images/contentpage/',

  //RestaurantImage photo upload 
  restaurantImageUploadDir: process.env.RESTAURANT_IMAGE_UPLOAD_DIR || '/images/restaurant-image/',

  //RestaurantImage photo upload 
  restaurantDocumentUploadDir: process.env.RESTAURANT_DOCUMENT_UPLOAD_DIR || '/images/restaurant-document/',

  //Partner Home page Image photo upload 
  partnerHomepageUploadDir: process.env.PARTNER_HOMEPAGE_UPLOAD_DIR || '/images/partner-homepage/',

  // Restaurant Item Image
  itemImageUploadDir: process.env.ITEM_IMAGE_UPLOAD_DIR || '/images/restaurant-itemImage/',

  paymentDeliveryUploadDir: process.env.PAYMENT_DELIVERY_UPLOAD_DIR || '/images/payment-delivery/',

  // Push Notification Server Key
  serverKey: process.env.PUSH_NOTIFICATION_SERVER_KEY,

  //Google map api key
  googleMapAPI: process.env.GOOGLE_MAP_API || 'AIzaSyDz9q1ygWRRPY7YlZo52E4y2c8Jk7YHnKU',
  
  // Web analytics
  analytics: {
    // https://analytics.google.com/
    googleTrackingId: process.env.GOOGLE_TRACKING_ID, // UA-XXXXX-X
  },

  // Payment - Stripe
  payment: { /* From ENV */
    stripe: {
      secretKey: process.env.STRIPE_SECRET
    }
  },

  //twilio sms credential
  sms: {
    twilio: {
      accountSid: process.env.TWILIO_ACCOUNT_SID,
      authToken: process.env.TWILIO_AUTHTOKEN,
      phoneNumber: process.env.TWILIO_PHONE_NUMBER
    }
  },

  // Email Configuration	
  emailConfig: {	
    host: process.env.SMTP_HOST,
    port: process.env.SMTP_PORT,	
    email: process.env.SMTP_LOGIN_EMAIL,	
    sender: process.env.SMTP_FROM_NAME,	
    senderEmail: process.env.SMTP_SENDER_EMAIL,	
    password: process.env.SMTP_LOGIN_PASSWORD,	
    secure: process.env.SMTP_SECURE,	
    tls: process.env.SMTP_TLS	
  },

  // Authentication
  auth: {
    jwt: { secret: process.env.JWT_SECRET },

    // https://developers.facebook.com/
    facebook: {
      id: process.env.FACEBOOK_APP_ID,
      secret: process.env.FACEBOOK_APP_SECRET
    },

    // https://cloud.google.com/console/project
    google: {
      id:
        process.env.GOOGLE_CLIENT_ID,
        secret: process.env.GOOGLE_CLIENT_SECRET
    },

    // https://apps.twitter.com/
    twitter: {
      key: process.env.TWITTER_CONSUMER_KEY,
      secret: process.env.TWITTER_CONSUMER_SECRET
    }
  },
};
